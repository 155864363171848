import moment from 'moment';
export default  {
	install(Vue) {
		Vue.filter('capitalize', function (value) {
			if (!value) return '';
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		});

		Vue.filter('upper', function (value) {
			if (!value) return ''
			value = value.toString();
			return value.toUpperCase();
		});

		Vue.filter('lower', function (value) {
			if (!value) return ''
			value = value.toString();
			return value.toLowerCase();
		});

		Vue.filter('jsonParsed', function (value) {
			if (!value) return ''
			try {
				return JSON.parse(value);
			} catch (e) {
				return value;
			}
		});

		Vue.filter('reverse', function(value) {
			// slice to make a copy of array, then reverse the copy
			return value.slice().reverse();
		});

		Vue.filter('formatDate', function(value) {
			return moment(value).format('YYYY-MM-DD')
		});

		Vue.filter('formatDateTime', function(value) {
			return moment(value).format('YYYY-MM-DD HH:mm:ss')
		});

		Vue.filter('formatDateTimeShort', function(value) {
			return moment(value).format('YYYY-MM-DD HH:mm')
		});

		Vue.filter('ellipsis18chars', function(value) {
			return value.length > 18 ? `${value.substr(0, 18)}...` : value;
		});
	}
}
