<template lang="pug">
	.nav-container
		b-navbar.direction-rtl(toggleable='lg', type='dark', variant='info')
			b-navbar-brand(href='#')

				.d-flex.justify-content-between
					.w-100
						b-img(:src="logo" :style="{width: '80%'}")
					b-navbar-toggle.v-mobile(v-if="loggedIn && role" target='nav-collapse')
					//font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'search-dollar']")
					//h3.ml-2 Search-Box
			//pre a{{role}}
			//b-navbar-toggle.v-desktop(v-if="loggedIn && role" target='nav-collapse')
			b-collapse#nav-collapse(is-nav='')
				b-navbar-nav
					b-nav-item(v-if="loggedIn && permit(4)", :to="'/'") Dashboard
					b-nav-item(v-if="loggedIn && permit(1) && permitCmu()", :to="'/users/list'") Sys. Users
					b-nav-item(v-if="loggedIn && permit(4)", :to="'/search'") Search
					b-nav-item(v-if="loggedIn && permit(1)", :to="{name: 'iframe-list'}") Integrations
					b-nav-item(v-if="loggedIn && permit(1)", :to="{name: 'job-list'}") Jobs
					b-nav-item(v-if="loggedIn && permit(1) && permitCvs()", :to="{name: 'settings'}") Settings
					//b-nav-item(v-if="loggedIn && permit(1)", :to="{name: 'balance-report'}") Balance
					//b-nav-item(v-if="loggedIn && permit(1) && permitCsl()", :to="'/logs'") Logs
					b-nav-item(v-if="loggedIn && permit(1)", :to="'/help'") Help
					b-nav-item(v-if="loggedIn", :to="'/about'") About
				updates-popup(v-if="loggedIn && permit(2)")
				b-navbar-nav.ml-auto
					b-nav-item-dropdown(v-if="loggedIn", left='')
						// Using 'button-content' slot

						template(slot='button-content')
							em Hi, {{username}}
						b-dropdown-item(:to="'/me'") My Profile
						b-dropdown-item(href='#', @click="logout") Log Out

</template>

<style lang="scss">
	.router-link-exact-active {
			color: rgb(184, 199, 209) !important;
			&.router-link-active:hover {
					color: rgb(205, 217, 225) !important;
			}
	}

	.nav-container {

		@media only screen and (max-width: 767px) {
			/*position: absolute;*/
			width: 100%;
			.navbar {
				z-index: 500;

			}

			.router-link-exact-active {
				background-color: #0d2e47 !important;
			}
		}
	}

	.direction-rtl {
		direction: ltr !important;

		&.bg-info {
			background-color: #0c1b31 !important;
		}

		@media only screen and (min-width: 768px) {
			ul.dropdown-menu.show {
				right: 0;
				left: -75px;
			}
		}

		@media only screen and (max-width: 767px) {
			flex-direction: row-reverse;
			text-align: start;
			ul.navbar-nav {
				padding-right: 0 !important;
			}
		}
	}
</style>

<script lang="js">
import UpdatesPopup from "@/components/updates";
	export default {
		name: 'Layout',
		components: {UpdatesPopup },
		computed: {
			loggedIn() {
				return this.$store.state.LOGGED_IN;
			},
			role() {
				return this.$store.getters.ROLE_ID;
			},
			cmu() {
				return this.$store.getters.CMU;
			},
			csl() {
				return this.$store.getters.CMU;
			},
			cvs() {
				return this.$store.getters.CVS;
			},
      username() {
        return this.$store.getters.LOGGED_USER.first_name + ' ' + this.$store.getters.LOGGED_USER.last_name;
      },
			logo() {
				return require(`../assets/${process.env.VUE_APP_LOGO}`)
			},
		},

		methods: {
			logout() {
				this.$store.dispatch('logout');
				this.$router.push({name: 'login'});
			},
			permit(minLvl) {
				return this.role <= minLvl
			},
			permitCmu() {
				return this.cmu == 'true';
			},
			permitCvs() {
				return this.cvs == 'true';
			},
			permitCsl() {
				return this.csl == 'true';
			}
		}
	}
</script>
