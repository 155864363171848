<template lang="pug">
	.finder-container
		#section-1
			el-row()
				el-col.mb-2(:xs="12", :sm="6")
					el-card.box-card
						.d-flex.justify-content-between
							font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'user-secret']")
							.count-text
								label Total Leads
								count-to.card-panel-num(:start-val='0', :end-val='panels.leads', :duration='2600')
				el-col.mb-2(:xs="12", :sm="6")
					el-card.box-card
						.d-flex.justify-content-between
							font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'users']")
							.count-text
								label Total Customers
								count-to.card-panel-num(:start-val='0', :end-val='panels.customers', :duration='2600')
				el-col.mb-2(:xs="12", :sm="6")
					el-card.box-card
						.d-flex.justify-content-between
							font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'clock']")
							.count-text
								label Daily Leads
								count-to.card-panel-num(:start-val='0', :end-val='panels.today_leads', :duration='2600')
				el-col.mb-2(:xs="12", :sm="6")
					el-card.box-card
						.d-flex.justify-content-between
							font-awesome-icon.icon.alt.big-icon(size="lg", :icon="['fa', 'user-clock']")
							.count-text
								label Daily Customers
								count-to.card-panel-num(:start-val='0', :end-val='panels.today_customers', :duration='2600')

		#section-2
			el-card.box-card.mt-3.filters-card
				el-form()
					el-row(:gutter="24")
						el-col(:xs="24" :sm="filters.brand.length > 4 ? 24 : 8")
							el-form-item(label="Filter Brands:")
								el-select(v-model="filters.brand" v-bind:class="{'w-100':filters.brand.length > 4 ? 24 : 8}" filterable="", :multiple="true", size="mini" placeholder="Filter by brand", @change="brandChanged")
									el-option.text-bold(label='All', value='all' :disabled="filters.brand.length > 0 && filters.brand[0] !== 'all'")
									el-option(v-for="o of brand_options", :label="o.t", :value="o.v" :disabled="filters.brand.length === 1 && filters.brand[0] === 'all'")
						el-col(:xs="24" :sm="filters.integration.length > 4 ? 24 : 8")
							el-form-item(label="Filter Integrations:" )
								el-select(v-model="filters.integration" v-bind:class="{'w-100':filters.integration.length > 4 ? 24 : 8}" filterable="", :multiple="true", size="mini"  placeholder="Filter by integrations" @change="fetchDataChart")
									el-option.text-bold(label='All', value='all' :disabled="filters.integration.length > 0 && filters.integration[0] !== 'all'")
									el-option(v-for="o of integration_options", :label="`${o.t}${o.n ? ` - (${o.n})` : ''}`", :value="o.v", v-if="filters.brand.includes(o.b) || filters.brand[0] === 'all'" :disabled="filters.integration.length === 1 && filters.integration[0] === 'all'")
						el-col(:xs="24" :sm="filters.funnel.length > 4 ? 24 : 8")
							el-form-item(label="Filter Funnels:")
								el-select(v-model="filters.funnel" v-bind:class="{'w-100':filters.funnel.length > 4 ? 24 : 8}" filterable="", :multiple="true", size="mini"  placeholder="Filter by funnels" @change="fetchDataChart")
									el-option.text-bold(label='All', value='all' :disabled="filters.funnel.length > 0 && filters.funnel[0] !== 'all'")
									el-option(v-for="o of funnel_options", :label="`${o.t}${o.n ? ` - (${o.n})` : ''}`", :value="o.v", v-if="(filters.brand.includes(o.b) || filters.brand[0] === 'all') && (filters.integration.includes(o.i) || filters.integration[0] === 'all')" :disabled="filters.funnel.length === 1 && filters.funnel[0] === 'all'")
			el-row.mt-3()
				el-col(:xs="24", :sm="8")
					el-card.box-card.mt-3(v-loading="pieData.brand.length === 0 || chartRefresh")
						.clickable.chart-div(@click="chooseChart(1)" )
							pieChart(v-if="!chartRefresh && pieData.brand.length > 0" class-name="tall-chart", :data-source="pieData.brand" :titleString="'Registration Distribution'" :subTitleString="'By Brands - 10 Biggest'")
							.mock-div(v-else="")
				el-col(:xs="24", :sm="8")
					el-card.box-card.mt-3(v-loading="pieData.funnel.length === 0 || chartRefresh")
						.clickable.chart-div(@click="chooseChart(2)" )
							pieChart(v-if="!chartRefresh && pieData.funnel.length > 0" class-name="tall-chart", :data-source="pieData.funnel" :titleString="'Registration Distribution'" :subTitleString="'By Funnels - 10 Biggest'")
							.mock-div(v-else="")
				el-col(:xs="24", :sm="8")
					el-card.box-card.mt-3(v-loading="pieData.countries.length === 0 || chartRefresh")
						.clickable.chart-div(@click="chooseChart(3)" )
							pieChart(v-if="!chartRefresh && pieData.countries.length > 0" class-name="tall-chart", :data-source="pieData.countries" :titleString="'Registration Distribution'" :subTitleString="'By Countries - 10 Biggest'")
							.mock-div(v-else="")

		#section-3
			el-row(:gutter="25")
				el-col(:xs="24", :sm="24")
					el-card.box-card.mt-3
						h4.text-left
							b 10 Last registrations
							hr
						el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='ten_last', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
							el-table-column(v-if="permit(3)" align="center" label="App" :width="50")
								template(slot-scope="scope" )
									font-awesome-icon.p-0(v-b-tooltip.html.hover-right="", :title="'Type: ' + scope.row.source_app", size="lg", :icon="[scope.row.source_app === 'IFRAME' ? 'fab' : 'far', scope.row.source_app === 'IFRAME' ? 'internet-explorer' : 'file-excel']")
							el-table-column(align='center', label='#' :width='75')
								template(slot-scope='scope')
									| {{ scope.row.id }}
							el-table-column( align='center', label='Full Name')
								template(slot-scope='scope')
									| {{ scope.row.first_name + ' ' + scope.row.last_name }}
							el-table-column( align='center', label="CID")
								template(slot-scope="scope")
									| {{ scope.row.cid || '-' }}
							el-table-column(align='center', label='E-Mail' :width="290")
								template(slot-scope='scope')
									small {{ scope.row.email || '-' }}
							el-table-column( align='center', label="Phone")
								template(slot-scope="scope")
									| {{ scope.row.phone || '-' }}
							el-table-column( align='center', label="Country", :width="90")
								template(slot-scope="scope")
									| {{ scope.row.country_object.name || '-' }}
							el-table-column( align='center', label="Timestamp")
								template(slot-scope="scope")
									| {{ scope.row.date_created || '-' }}
							el-table-column( align='center', label="Brand")
								template(slot-scope="scope")
									| {{ scope.row.brand.name || '-' }}
							el-table-column(v-if="permit(3)"  align='center', label="Funnel")
								template(slot-scope="scope")
									| {{ scope.row.funnel ? scope.row.funnel.name : '-' }}
		el-dialog(title='', :visible.sync='chartDialogOpen', width='80%', center='', @closed="closeChartModal")
			pieChart(v-if="currentChart" class-name="taller-chart", :data-source="currentChart"  :legends="currentLegend" :dim="{outerRadius: 165, marginTop: '44%'}" :titleString="currentTitle.title" :subTitleString="currentTitle.subtitle")
</template>


<style lang="scss">
.finder-container {
	padding-top: 1rem;
	margin: 1rem;
	@media only screen and (max-width: 767px) {
		padding-top: 0.25rem;
		margin: 0.25rem;
	}

	#section-1 {
		.box-card {
      height: 9rem;

			&:hover {
				cursor: pointer;
				background-color: #0d2e47;
				color: #fafafa;

				.big-icon {
					color: white;
				}
			}

			@media only screen and (max-width: 767px) {
				margin-top: 0.25px;
				margin-bottom: 0.25px;
				margin-left: 0.25rem;
				margin-right: 0.25rem;
			}
			background: #fff;
			padding: 16px 16px 0;
			margin-top: 1px;
			margin-bottom: 1px;
			margin-left: 1rem;
			margin-right: 1rem;

			.text-bold {
				font-weight: 600;
			}

			.funnels-tbl {
				font-size: 13px;
			}

			.big-icon {
				width: 40px;
				height: 40px;
				color: #0d2e47;
			}

			.card-panel-num {
				font-size: 20px;
			}

			.count-text {
				text-align: right;

				label {
					display: block;
				}
			}

		}
	}

	#section-2, #section-3 {

		.mock-div {
			height: 400px;
		}

		.chart-div {
			& > canvas {
				canvas {
					cursor: pointer;
				}
			}
		}

		.filters-card {
			.el-card__body {
				padding: 0;
				@media only screen and (max-width: 767px) {
					padding-left: 24px;
					padding-right: 24px;
				}
			}
		}

		@media only screen and (min-width: 768px) {
			.box-card {
				background: #fff;
				padding: 16px 16px 0;
				margin-top: 1px;
				margin-bottom: 1px;
				margin-left: 1rem;
				margin-right: 1rem;

				.text-bold {
					font-weight: 600;
				}

				.funnels-tbl {
					font-size: 13px;
				}

				.big-icon {
					width: 40px;
					height: 40px;
					color: #0d2e47;
				}

				.card-panel-num {
					font-size: 20px;
				}

				.count-text {
					text-align: right;

					label {
						display: block;
					}
				}

			}
		}

		@media only screen and (max-width: 767px) {
			.el-card {
				margin-top: 0 !important;
				margin-bottom: 10px !important;
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	}

	.long-text {
		display: inline-block;
		width: 100%;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
}
</style>


<script lang="js">
import pieChart from '../components/pie';
import CountTo from 'vue-count-to'

export default {
	components: {CountTo, pieChart},
	data() {
		return {
			fetchWatcher: null,
			chartDialogOpen: false,
			currentChart: null,
			currentLegend: [],
			currentTitle: {
				title: '',
				subtitle: ''
			},
			busy: false,
			chartRefresh: false,
			panels: {
				leads: 0,
				customers: 0,
				today_leads: 0,
				today_customers: 0,

			},
			filters: {
				brand: [],
				funnel: [],
				integration: []
			},
			ten_last: [],
			pie: {
				brand: [],
				funnel: [],
				countries: []
			},
			brand_options: [],
			funnel_options: [],
			integration_options: [],
		}
	},
	computed: {
		brandTitle: function() {
			let hash = {};
			this.pie.brand.forEach(e => hash[e.name] = '')
			return Object.keys(hash);
		},
		funnelTitle: function() {
			let hash = {};
			this.pie.funnel.forEach(e => hash[e.name] = '')
			return Object.keys(hash);
		},
		countriesTitle: function() {
			let hash = {};
			this.pie.countries.forEach(e => hash[e.name] = '')
			return Object.keys(hash);
		},
		pieData() {
			return {
				brand: this.pie.brand,
				funnel: this.pie.funnel,
				countries: this.pie.countries
			}
		}
	},
	async created() {
		this.fetchData();
		let result = await this.$apix.sendHttpRequest('GET', 'components/options', {filters: '9e832481-2c11-4021-ba13-0dcc5487e3f7,1e0c327a-73cf-4159-b154-ec498ade6dcd,49f06cec-46b0-4237-bf9a-d4d3969e5af6'});
		this.brand_options = result.brand;
		this.funnel_options = result.funnel_extra;
		this.integration_options = result.integration_extra
	},
	mounted() {
		this.fetchWatcher = setInterval(() => {
			this.fetchData()
		}, 60000)
	},
	beforeDestroy() {
		clearInterval(this.fetchWatcher)
	},
	methods: {
		closeChartModal() {
			// this.chartDialogOpen = !this.chartDialogOpen;
			this.currentChart = null;
			this.currentLegend = null;
		},
		chooseChart(num) {
			this.chartDialogOpen = !this.chartDialogOpen;
			switch(num) {
				case 1:
					this.currentChart = this.pie.brand;
					this.currentLegend = this.brandTitle;
					this.currentTitle = { title: 'Registration Distribution', subtitle: 'Per Brands' };
					break;

				case 2:
					this.currentChart = this.pie.funnel;
					this.currentLegend = this.funnelTitle;
					this.currentTitle = { title: 'Registration Distribution', subtitle: 'Per Funnels' };
					break;

				case 3:
					this.currentChart = this.pie.countries;
					this.currentLegend = this.countriesTitle;
					this.currentTitle = { title: 'Registration Distribution', subtitle: 'Per Countries' };
					break;
			}
		},
		permit(minLvl) {
			return this.$store.state.ROLE_ID <= minLvl
		},
		fetchDataChart() {
			this.chartRefresh = true;
			this.fetchData().then(() => setTimeout(() => this.chartRefresh = false), 100);
		},
		brandChanged() {
			if(this.filters.brand === 'all') {
				this.filters.funnel = [];
				this.filters.integration = [];
			}
			this.chartRefresh = true;
			this.fetchData().then(() => setTimeout(() => this.chartRefresh = false), 100);
		},
		async fetchData() {
			let params = {};
			this.$apix.sendHttpRequest('GET', 'dashboard/get', {
				brand: this.filters.brand.length > 0 ? this.filters.brand.join(',') : 'all',
				funnel: this.filters.funnel.length > 0 ? this.filters.funnel.join(',') : 'all',
				integration: this.filters.integration.length > 0 ? this.filters.integration.join(',') : 'all'
			})
					.then(res => {
						this.panels = res.panels;
						this.ten_last = res.ten_last;
						this.pie = res.pie;
					})
		}
	}
}
</script>
